.App {
	font-family: Helvetica, Arial, sans-serif;
	color: #404448;
	background-color:#ffffff;
	margin: 4px;
}

a, a:visited {
	color: #999933;
	text-decoration: none;
}
a:hover {
	color: #cc9900;
	text-decoration: underline;
}
